import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';

import Image from '../components/image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import STYLES from '../styles/const';

const GreetingsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { title: { eq: "greetings" } } }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);
  const { node } = data.allMarkdownRemark.edges[0];

  return (
    <Layout path="/greetings">
      <SEO
        title="代表取締役の挨拶"
        description="日本は、小さな島国ですから、国内の今後の民間及び、公共事業の骨材不足が明らかであり、海外からの資材供給が不可欠であります。私たちはアジア諸国からの供給契約を既に済ませており、需要に応じて、準備しております。"
      />
      <Wrapper>
        <Text>
          <Content dangerouslySetInnerHTML={{ __html: node.html }} />
          <Signature>
            <SignatureContainer>
              <div>
                <span>株式会社アールエムシー</span>
              </div>
              <SignatureName>
                <span>代表取締役</span>
                <SignatureImage>
                  <Image filename="greetings-signature.png" alt="佐々木司" />
                </SignatureImage>
              </SignatureName>
            </SignatureContainer>
          </Signature>
        </Text>
        <ImageWrapper>
          <Image filename="greetings-main.jpg" alt="代表取締役の挨拶" />
        </ImageWrapper>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  display: flex;
  /* padding-top: 40px; */
  padding: 60px 40px 0;
`;

const Text = styled.div`
  width: 450px;
  padding-right: 40px;
  font-family: ${STYLES.FONT.FAMILY.MINCHO};
`;

const Content = styled.div`
  margin-bottom: 18px;
  font-size: 14px;
  line-height: 1.8;
`;

const Signature = styled.div`
  display: flex;
  justify-content: flex-end;
  font-family: ${STYLES.FONT.FAMILY.MINCHO};
  font-size: 15px;
`;

const SignatureContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SignatureName = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const SignatureImage = styled.div`
  width: 110px;
  margin-left: 10px;
`;

const ImageWrapper = styled.div`
  flex: 1;
`;

export default GreetingsPage;
